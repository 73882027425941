<template>
  <div>
    <FormView
      :formData="fromData"
      :formLabels="formLabels"
      :column="formColumn"
      :labelWidth="labelWidth"
    >
      <el-button type="success" @click="handleClickAdd">新建优惠券</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
      <el-button type="primary" @click="search">查询</el-button>
    </FormView>
    <el-tabs
      v-model="activeNameTab"
      class="demo-tabs"
      @tab-click="handleClickTab"
    >
      <el-tab-pane :label="`全部（${tabData.all}）`" :name="0"></el-tab-pane>
      <el-tab-pane :label="`未开始（${tabData.not}）`" :name="1"></el-tab-pane>
      <el-tab-pane
        :label="`进行中（${tabData.conduct}）`"
        :name="2"
      ></el-tab-pane>
      <el-tab-pane :label="`已结束（${tabData.end}）`" :name="3"></el-tab-pane>
    </el-tabs>
    <TableView
      :loading="loading"
      :columns="columns"
      :data="rTableData"
      :pagination="pagination"
      :is-pagination-show="isPaginationShow"
      @getData="getDataList"
    >
      <template #type>
        <el-table-column label="卡券类型" align="center">
          <template #default="scoped">
            <span>
              {{ typeText(scoped.row.type) }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #content>
        <el-table-column label="卡券内容" align="center" width="260">
          <template #default="scoped">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="scoped.row.content"
              placement="top"
            >
              <div class="kaqcontent" >
                {{ scoped.row.content }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
      </template>
      <template #getType>
        <el-table-column label="领取方式" align="center">
          <template #default="scoped">
            <span>
              {{ getTypeText(scoped.row.getType) }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #getTimeStart>
        <el-table-column label="领券时间" align="center">
          <template #default="scoped">
            <span v-if="scoped.row.getTimeStart">
              {{ scoped.row.getTimeStart + ' ~ ' + scoped.row.getTimeEnd }}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </template>
      <template #sendTotal>
        <el-table-column label="发放数量" align="center">
          <template #default="scoped">
            <span>
              {{
                scoped.row.sendTotalType == 1 ? '不限制' : scoped.row.sendTotal
              }}
            </span>
          </template>
        </el-table-column> </template
      >发放数量
      <template #receivedNum>
        <el-table-column label="已使用/已领取" align="center">
          <template #default="scoped">
            <span>
              {{ scoped.row.usedNum || '0' }}
            </span>
            <span>/</span>
            <span>
              {{ scoped.row.receivedNum || '0' }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #usedNum>
        <el-table-column label="使用率" align="center">
          <template #default="scoped">
            <span>
              {{ usageRate(scoped.row) }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #state>
        <el-table-column label="优惠券状态" align="center">
          <template #default="scoped">
            <span>
              {{ stateText(scoped.row.state) }}
            </span>
          </template>
        </el-table-column>
      </template>
      <template #action>
        <el-table-column label="操作" align="center" width="300">
          <template #default="scoped">
            <el-button
              type="primary"
              size="small"
              @click="handleClickEdit(scoped.row)"
              v-if="scoped.row.state == 1"
            >
              修改
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickDelete(scoped.row)"
              v-if="scoped.row.state == 1 || scoped.row.state == 3"
            >
              删除
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickStart(scoped.row)"
              v-if="scoped.row.state == 1"
            >
              开始
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickEnd(scoped.row)"
              v-if="scoped.row.state == 2"
            >
              结束
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickIssue(scoped.row)"
              v-if="scoped.row.state == 2 && scoped.row.getType != 1"
            >
              发券
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickAdjustment(scoped.row)"
              v-if="scoped.row.state == 2 && scoped.row.sendTotalType != 1"
            >
              调整数量
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleClickDetails(scoped.row)"
              v-if="scoped.row.state == 2 || scoped.row.state == 3"
            >
              详情
            </el-button>
          </template>
        </el-table-column>
      </template>
    </TableView>
  </div>
</template>

<script setup>
import { reactive, onMounted, h, toRefs, ref } from 'vue'
import TableView from '@/components/TableView.vue'
import FormView from '@/components/FormView.vue'
import { ElMessage, ElMessageBox, ElInput } from 'element-plus'
import {
  couponReset,
  couponStart,
  couponEnd,
  couponDel,
  couponDistribution,
  queryCriteria
} from '@/api/cardManagement/platform'
import { useRouter } from 'vue-router'
const router = useRouter()
const initData = reactive({
  formLabels: [
    {
      label: 'keyword',
      title: '关键字',
      placeholder: '请输入关键字以搜索'
    },
    {
      label: 'type',
      title: '卡券类型',
      placeholder: '请选择',
      type: 'options',
      options: [
        {
          label: '现金满减券',
          value: 1
        },
        {
          label: '有赞券',
          value: 2
        },
        {
          label: '活动券',
          value: 3
        }
      ]
    },
    {
      label: 'getType',
      title: '领取方式',
      placeholder: '请选择',
      type: 'options',
      options: [
        {
          label: '用户领取',
          value: 1
        },
        {
          label: '系统发放',
          value: 2
        }
      ]
    },
    {
      label: 'useGoodsType',
      title: '使用范围',
      placeholder: '请选择',
      type: 'options',
      options: [
        {
          label: '全部通用',
          value: 1
        },
        {
          label: '指定商品可用',
          value: 2
        },
        {
          label: '指定商品不可用',
          value: 3
        }
      ]
    }
  ],
  fromData: {
    // 表单数据
    keyword: '',
    type: '',
    getType: '',
    useGoodsType: ''
  },
  formColumn: 4,
  labelWidth: '90px',
  loading: false,
  pagination: {
    current: 1,
    size: 10,
    total: 0
  },
  isPaginationShow: true,
  rTableData: [],
  columns: [
    {
      index: true,
      indexMethod(index) {
        return index + 1
      },
      width: 70,
      label: '编号'
    },
    { prop: 'name', label: '卡券名称' },
    { slot: 'type' },
    { slot: 'content' },
    { slot: 'getType' },
    { slot: 'getTimeStart' },
    { slot: 'sendTotal' },
    { slot: 'receivedNum' },
    { slot: 'usedNum' },
    { slot: 'state' },
    { prop: 'createTime', label: '创建时间' },
    { slot: 'action' }
  ],
  labelVisible: false,
  froms: {},
  activeNameTab: 0,
  tabData: {
    all: 0,
    conduct: 0,
    end: 0,
    not: 0
  }
})

const {
  loading,
  pagination,
  isPaginationShow,
  rTableData,
  columns,
  formLabels,
  fromData,
  formColumn,
  labelWidth,
  activeNameTab,
  tabData
} = toRefs(initData)

onMounted(() => {
  getDataList()
})

// 重置
const reset = () => {
  fromData.value = {
    keyword: '',
    type: '',
    getType: '',
    useGoodsType: ''
  }
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 查询
const search = () => {
  pagination.value.current = 1
  pagination.value.size = 10
  getDataList()
}

// 获取列表数据
const getDataList = (val, flag) => {
  loading.value = true
  if (flag == 'size') {
    pagination.value.size = val || 10
  } else {
    pagination.value.current = val || 1
  }
  const data = {
    current: pagination.value.current,
    size: pagination.value.size
  }
  if (activeNameTab.value != 0) {
    data.state = activeNameTab.value
  }
  if (fromData.value.keyword !== '') {
    data.keyword = fromData.value.keyword
  }
  if (fromData.value.type !== '') {
    data.type = fromData.value.type
  }
  if (fromData.value.getType !== '') {
    data.getType = fromData.value.getType
  }
  if (fromData.value.useGoodsType !== '') {
    data.useGoodsType = fromData.value.useGoodsType
  }
  queryCriteria(data).then(({ data: res }) => {
    loading.value = false
    if (res.code == 200) {
      rTableData.value = res.data.records
      pagination.value.total = Number(res.data.pageInfo.total)
      tabData.value = res.data.extend
    } else {
      ElMessage.error(res.msg)
      rTableData.value = []
      pagination.value.total = 0
    }
  })
}

// 切换tab
const handleClickTab = val => {
  activeNameTab.value = val.props.name
  search()
}

// 领取方式
const getTypeText = val => {
  let vals = ''
  switch (val) {
    case 1:
      vals = '用户领取'
      break
    case 2:
      vals = '系统发放'
      break
  }
  return vals
}

const typeText = val => {
  let vals = ''
  switch (val) {
    case 1:
      vals = '现金满减券'
      break
    case 2:
      vals = '有赞券'
      break
    case 3:
      vals = '活动券'
      break
  }
  return vals
}

// 使用率
const usageRate = row => {
  if (row.usedNum > 0 && row.receivedNum > 0) {
    return ((row.usedNum / row.receivedNum) * 100).toFixed(2)
  } else {
    return '-'
  }
}

// 优惠券状态
const stateText = val => {
  let vals = ''
  switch (val) {
    case 1:
      vals = '未开始'
      break
    case 2:
      vals = '进行中'
      break
    case 3:
      vals = '已结束'
      break
  }
  return vals
}

// 新建
const handleClickAdd = row => {
  router.push({
    path: '/platformCommon'
  })
}

// 修改
const handleClickEdit = row => {
  router.push({
    path: '/platformCommon',
    query: {
      id: row.id,
      flag: 'edit'
    }
  })
}

// 删除
const handleClickDelete = row => {
  ElMessageBox.confirm(
    `是否确认删除【${row.name}】优惠券(该操作不可逆，已发放的卡券将直接作废)？`,
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      couponDel({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 开始
const handleClickStart = row => {
  ElMessageBox.confirm(
    `是否确认开始发放【${row.name}】优惠券（该操作不可逆）？`,
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      couponStart({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 结束
const handleClickEnd = row => {
  ElMessageBox.confirm(
    `是否确认结束发放【${row.name}】优惠券，结束发放的优惠券不能开始新的领取（该操作不可逆）？`,
    '提示：',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(() => {
      couponEnd({ id: row.id }).then(({ data: res }) => {
        if (res.code == 200) {
          ElMessage.success(res.msg)
          getDataList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {})
}

// 发券
const handleClickIssue = row => {
  router.push({
    path: '/issueCards',
    query: {
      id: row.id
    }
  })
}

// 调整数量
const handleClickAdjustment = row => {
  if (row.sendTotalType == 1) {
    ElMessage.warning('请修改发放数量后再进行调整')
    return false
  }
  const sendTotal = ref('')
  ElMessageBox({
    title: '提示',
    showCancelButton: true,
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    message: () =>
      h('div', [
        h(
          'p',
          `【${row.name}】优惠券原数量：${row.sendTotal}张，调整后的数量必须大于原数量`
        ),
        h('span', '调整后优惠券数量：'),
        h(ElInput, {
          modelValue: sendTotal.value,
          'onUpdate:modelValue': val => (sendTotal.value = val),
          style: 'width:80px;margin-right:5px'
        })
      ])
  })
    .then(() => {
      if (sendTotal.value < row.sendTotal) {
        ElMessage.warning('调整后的数量必须大于原数量')
        return Promise.reject()
      }
      couponReset({ id: row.id, num: sendTotal.value }).then(
        ({ data: res }) => {
          if (res.code == 200) {
            ElMessage.success(res.msg)
            getDataList()
          } else {
            ElMessage.error(res.msg)
          }
        }
      )
    })
    .catch(action => {
      console.log(action)
    })
}

// 详情
const handleClickDetails = row => {
  router.push({
    path: '/platformCommon',
    query: {
      id: row.id,
      flag: 'details'
    }
  })
}
</script>
<style lang="scss" scoped>
.kaqcontent {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
