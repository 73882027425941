import request from '../Axios'

// 列表
export const queryCriteria = data => {
  return request({
    url: '/coupon/queryCriteria',
    method: 'post',
    data
  })
}

// 创建卡券
export const couponAdd = data => {
  return request({
    url: '/coupon/add',
    method: 'post',
    data
  })
}

// 编辑卡券
export const couponEdit = data => {
  return request({
    url: '/coupon/edit',
    method: 'put',
    data
  })
}

// 调整卡券数量
export const couponReset = params => {
  return request({
    url: `/coupon/reset/num/${params.id}?num=${params.num}`,
    method: 'put'
  })
}

// 开始发券
export const couponStart = params => {
  return request({
    url: `/coupon/start/${params.id}`,
    method: 'put'
  })
}

// 结束发券
export const couponEnd = params => {
  return request({
    url: `/coupon/end/${params.id}`,
    method: 'put'
  })
}

// 删除卡券
export const couponDel = params => {
  return request({
    url: `/coupon/del/${params.id}`,
    method: 'delete'
  })
}

// 发放卡券
export const couponDistribution = data => {
  return request({
    url: '/coupon/distribution',
    method: 'post',
    data
  })
}

// 卡券详情
export const couponDetail = params => {
  return request({
    url: `/coupon/detail/${params.id}`,
    method: 'get'
  })
}


// 卡券发放记录列表
export const hisQueryCriteria = data => {
  return request({
    url: '/coupon/his/queryCriteria',
    method: 'post',
    data
  })
}

// 卡券发放记录作废/批量作废卡券
export const couponHisRepeal = data => {
  return request({
    url: `/coupon/his/repeal`,
    method: 'put',
    data
  })
}

// 上传
export const couponUploadExcel = data => {
  return request({
    url: '/coupon/uploadExcel',
    method: 'post',
    data
  })
}

// 按用户等级
export const queryCouponDistribution = data => {
  return request({
    url: '/coupon/query/distribution',
    method: 'post',
    data
  })
}
